import React from 'react';

import config, { heading } from '../../config';
const pic = require('../assets/img/avatar.png');

export default function Footer() {
  return (
    <header>
      <span className="avatar">
        <img src={pic} alt="" />
      </span>
      <h1>{config.authorName}</h1>
      <div>{config.headings.map((heading, idx) => (
        <p key={idx}>{heading}</p>
      ))}</div>
    </header>
  );
}
