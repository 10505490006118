module.exports = {
  siteTitle: 'Caron Thomas',
  manifestName: 'Caron Thomas',
  manifestShortName: 'Caron T',
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`,
  authorName: 'Caron Thomas',
  heading: 'Developer - Photographe',
  headings : ['Developpeur', '✖', 'Photographe' ],
  // social
  twitterName : "thomas_caron",
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/caront',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/thomas_caron',
    },
    {
      icon: 'fa-flickr',
      name: 'flickr',
      url : 'https://www.flickr.com/photos/151692875@N05/'
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/thomas_caron_/',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:thomas.caron@epitech.eu',
    },
  ],
};
